<template>
  <div class="entity-manage bg-f">
    <!-- <div class="bar"></div> -->
    <div class="operate-area dp-flex align-item-c p-16">
      <div class="mr-10"><i class="el-icon-s-operation mr-5"></i>数据列表</div>
      <el-button type="primary" size="small" @click="addLable(false)">添加</el-button>
    </div>
    <div class="table-wrap">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :height="tableHeight - 40"
        >
        <el-table-column prop="id" label="id"  width="120px"> </el-table-column>
        <el-table-column prop="name" label="名称" width="120px"></el-table-column>
        <el-table-column label="个号">
          <template slot-scope="scope">
            <span v-if="scope.row.thirdLoginUsers && scope.row.thirdLoginUsers.length">
              <el-tag
                :key="tag.id"
                v-for="tag in scope.row.thirdLoginUsers"
                :disable-transitions="false"
                style="margin:5px"
                size="mini"
               >
                {{tag.thirdUser.displayName || tag.thirdUser.thirdUserName}}
              </el-tag>
            </span>
            <span v-else> -- </span>
            <span><el-button icon="el-icon-edit" type="text" size="mini" @click="editLabel(scope.row)"></el-button></span>
          </template>
        </el-table-column>
       
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
              <el-button class="operate-link" type="text" @click="addLable(scope.row)">编 辑</el-button >
              <el-button class="operate-link" type="text" @click="deleLabel(scope.row)">删 除</el-button >
            </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="标签" :visible.sync="dialogFormVisible" width="600px">
        <el-form :model="form" label-width="120px">
            <el-form-item label="标签名称：">
                <el-input v-model="form.name" autocomplete="off" placeholder="请输入标签名称"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="add">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog title="个号" :visible.sync="dialogNumVisible" width="600px">
        <el-form :model="form" label-width="120px">
            <el-form-item label="个号：">
               <el-select v-model="formNum.fbUserIds" multiple placeholder="请选择" filterable clearable style="width:100%">
                  <el-option
                    v-for="item in numLists"
                    :key="item.id"
                    :label="item.thirdUser.displayName || item.thirdUser.thirdUserName"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogNumVisible = false">取 消</el-button>
            <el-button type="primary" @click="addThirdUser">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { tableHeight, fnCSTToYmd } from "@/utils/utils.js";
import { queryLabel, saveLabel,delLabel,saveLabelFbUsers } from "@/api/thirdUserLabel";
export default {
  data() {
    return {
      searchForm: {},
      tableHeight: 600,
      tableData: [],
      tableHeightData:[
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".page", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".entity-manage .search-form", cssProperty: "height" },
        { selector: ".entity-manage .operate-area", cssProperty: "height" },
      ],
      dialogFormVisible:false,
      dialogNumVisible:false,
      form:{
          name:''
      },
      formNum:{
          fbUserIds:[]
      }
    };
  },
  components: {
  },
  computed:{
    ...mapState("num", ["selectedNum", "numLists","selectNumLable"]),
  },
  methods: {
    addLable(row) {
        if(row){
            this.rowMsg = {...row};
            this.form.name = row.name;
        }else{
            this.rowMsg = null;
            this.form.name = '';
        }
      this.dialogFormVisible = true;
    },
    editLabel(row){
      this.rowMsg = {...row};
      this.formNum.fbUserIds = row.thirdLoginUsers && row.thirdLoginUsers.length?row.thirdLoginUsers.map(v=>v.id):[];
      this.dialogNumVisible = true;
    },
    addThirdUser(){
      saveLabelFbUsers({fbUserIds:this.formNum.fbUserIds,labelId:this.rowMsg?.id}).then((res) => {
            if(res.code == 0){
                this.dialogNumVisible = false;
                this.$message({
                    type:'success',
                    message:'添加成功'
                })
            }
            this.getList();
            this.$bus.$emit("trigger-myLoginList", "facebook");
        });
    },
    add(){
        if(!this.form.name){
            this.$message({
                type:'warning',
                message:'请输入名称'
            })
            return false;
        }
        saveLabel({name:this.form.name,id:this.rowMsg?.id}).then((res) => {
            if(res.code == 0){
                this.dialogFormVisible = false;
                this.$message({
                    type:'success',
                    message:'添加成功'
                })
            }
            this.getList();
            this.$bus.$emit("trigger-myLoginList", "facebook");
        });
    },
    deleLabel(row) {
      this.$confirm("是否要删除该标签并且删除该标签的关联关系吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          delLabel({id: row.id}).then(res=>{
            if(res.code == 0){
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
              this.$bus.$emit("trigger-myLoginList", "facebook");
            }
          })
      })
    },
    getList() {
      // this.searchForm.seqNo = this.searchForm.seqNo?(this.searchForm.seqNo):''
      queryLabel({}).then((res) => {
        console.log(res);
        this.tableData = res.data;
      });
    },
  },
  mounted() {
    this.getList();
    this.$nextTick(() => {
      if (this.tableHeightData.length) {
        this.tableHeight = tableHeight(this.tableHeightData);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/commonly";
.entity-manage {
  .table-wrap {
    padding: 16px;
  }
  .el-form {
    padding: 16px 16px 0;
  }
  .bar {
    height: 16px;
    background-color: #f5f5f5;
  }
  .operate-area {
    background-color: #fafafa;
  }
  .entity-table {
    th {
      background-color: #fafafa;
    }
  }
}
</style>
